import { useState } from "react";
import { DialogModal, DropdownOptions, HPHButton, IconButton, InputDropdown } from "veronica-ui-component/dist/component/core";
import { IPreferenceModal } from "./SaveNewPreferenceModal";
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { useProposalEnquiryVM } from "presentation/hook/ProposalEnquiry/useProposalEnquiryVM";

export const DeletePreferenceModal: React.FC<IPreferenceModal> = (props: IPreferenceModal) => {
    const { visible } = props;
    const [proposalEnquiryState] = useProposalEnquiryTracked();
    const proposalEnquiryVM = useProposalEnquiryVM();
    const [preferenceKey, setPreferenceKey] = useState('');
    const userEmail = proposalEnquiryState.userEmail;

    const onConfirmClicked = () => {        
        proposalEnquiryVM.onShowLoading();
        proposalEnquiryVM.deleteSearchPreference(userEmail, preferenceKey).then(data => {
            proposalEnquiryVM.loadAllSearchPreference(userEmail).then(data => {
                proposalEnquiryVM.onHideLoading();
            }).catch(error => {
                proposalEnquiryVM.onHideLoading();
            })
        }).catch(error => {
            proposalEnquiryVM.onHideLoading();
        })
    }

    return <DialogModal
        appendTo='self'
        showButton={false}
        visible={visible}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">DELETE PREFERENCE</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={proposalEnquiryVM.closeConfirmModal} tooltipDisable={true}/>
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">
                <InputDropdown label='Name' inputType="freeText" value={preferenceKey} onChange={(e: DropdownOptions) => setPreferenceKey(e?.value ?? '')}
                    options={proposalEnquiryState.searchPreferences?.filter((searchPreference) => searchPreference.userEmail === userEmail)?.map(searchPreference => ({
                        dropdownLabel: searchPreference.preferenceName,
                        tagLabel: searchPreference.preferenceName,
                        value: searchPreference.preferenceName,
                    }))}/>             
            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={'Confirm'} size={'Small'} theme={'Primary'} onClick={onConfirmClicked}/>
            </div>
        }
    />;
};
