import { SelectionChangedEvent } from "ag-grid-community";
import { customDispatchEvent, E_Custom_Dispatch_Event, E_Type_Of_Event } from "helpers/Events";
import { INITIAL_PROPOSAL_ENQUIRY_HEADER_COL_DEF, transferRowData } from "presentation/constant/ProposalEnquiry/ProposalEnquiryHeaderColumnDefinition";
import { useProposalEnquiryVM } from "presentation/hook/ProposalEnquiry/useProposalEnquiryVM";
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { useCallback, useRef } from "react";
import { IconButton, Loader } from "veronica-ui-component/dist/component/core";


export const ProposalEnquiryTablePanel = () => {
    const [proposalEnquiryState] = useProposalEnquiryTracked();
    const isShowRightCriteriaPanel = proposalEnquiryState.isShowRightCriteriaPanel;
    const proposalEnquiryVM = useProposalEnquiryVM();
    // const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    // const [proposals, setProposals] = useState(proposalEnquiryState.proposals);
    let gridRef: any = useRef();
    const tableDoubleClicked = (data: any) => {
        // if (data) {
        //     customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_REDIRECT_EVENT, 
        //         E_Custom_Dispatch_Event.REDIRECT_PROPOSAL_ENQUIRY_DETAIL_FROM_HEADER, 
        //         { userName: anaInfoState.userName,
        //             data: data.data,
        //         }
        //     );
        // }
        customDispatchEvent(E_Type_Of_Event.MODAL_EVENT, E_Custom_Dispatch_Event.MODAL_FULL_SCREEN_MODE, { isFullScreen: true, className: 'proposal-search-modal' });
        proposalEnquiryVM.openProposalDetail(data.data);
    }

    // useEffect(() => {
    //     setProposals(proposalEnquiryState.proposals);
    // }, [proposalEnquiryState.proposals])

    // useEffect(() => {
    //     if (onTableSelectionClicked) return;

    //     gridRef.current?.gridRef.current.api?.deselectAll();
    // }, [onTableSelectionClicked])

    // useEffect(() => {
    //     // const columnDefs = (INITIAL_PROPOSAL_ENQUIRY_HEADER_COL_DEF?.slice());

    //     // if (!proposalEnquiryState.selectedRows ||
    //     //     proposalEnquiryState.selectedRows.length <= 0) {        
    //     //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
    //     if (!onTableSelectionClicked) {
    //         gridRef.current?.gridRef.current.api?.deselectAll();
    //     }
    //     // }
    // })

    // useEffect(() => {
    //     customEventListener(E_Type_Of_Event.PROPOSAL_ENQUIRY_REFRESH_EVENT, onProposalEnquiryTableRefresh);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // const onProposalEnquiryTableRefresh = (e: any) => {
    //     switch (e.detail.action) {
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_UPDATE:
    //             if (e.detail.data.tableList) {
    //                 setProposals(e.detail.data.tableList);
    //             }
    //             setIsLoading(e.detail.data.isLoading);
    //             setPanelZIndex(calculatePosition());
    //             break;
    //     }
    // }

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        // setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        proposalEnquiryVM.updateSelectedRowsActivate(selectedRows);
    }, [proposalEnquiryVM])

    const calculatePosition = useCallback(() => {
        const pDiv = document.querySelector(`.charge-data-box.filter-preview`);
        if (pDiv) {
            let currentElement = pDiv as HTMLElement;
            let width = currentElement.clientWidth - 22;
            let height = currentElement.clientHeight - 2;
            return { width: width, height: height };
        } else {
            return {};
        }
    }, [])

    return <div className={`table-ag-gird im-charge-data-search-table-panel ag-theme-alpine`} style={{ position: 'relative' }}>
        {(proposalEnquiryState.isDoing) && <Loader Indicator="Spinner" size="Medium" style={{ ...calculatePosition(), position: 'fixed', backgroundColor: 'rgba(255,255,255,0.5)' }} />}
        <div className={`im-charge-data-search-table-panel-content ag-theme-alpine`}>
            <NbisTable
                id='proposal-enquiry-table'
                columns={INITIAL_PROPOSAL_ENQUIRY_HEADER_COL_DEF?.slice()}
                cacheBlockSize={30}
                isNewColumnSetting={true}
                isScrollHighlighted={true}
                showPaginator={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                showUploadIcon={true}
                gridHeight="customHeight"
                customHeight={"calc(100% - 25px)"}
                onRowDoubleClick={(e: any) => tableDoubleClicked(e)}
                selectionMode={true}
                rowSelection={"multiple"}
                suppressRowClickSelection={false}
                handleSelectionChanged={handleSelectionChange}
                rowHeight={28}
                rowBuffer={20}
                ref={gridRef}
                data={transferRowData(proposalEnquiryState.proposals ?? [])}
                // groupDisplayType={"groupRows"}
                // rowGroupPanelShow={"always"}
                autoMarginLeft={true}
                entrypoint={'proposalEnquiry'}
                mustRefresh={true}

                columnSettingIconList={[
                    <>
                        <IconButton fileName={isShowRightCriteriaPanel ? "Icon-angle-left" : "Icon-angle-right"} size="medium" toolTipText={isShowRightCriteriaPanel ? "Close Right Panel" : "Open Right Panel"} toolTipArrow={false} onClick={proposalEnquiryVM.onHideRightCriteriaPanel} />
                    </>
                ]}
            />
        </div>
    </div>;
}