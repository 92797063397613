import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { EMPTY_SEARCH_PREFERENCE_ENTITY, type SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import type { EnabledProposalEnquiryCriteria } from "domain/entity/ProposalEnquiry/EnabledProposalEnquiryCriteria";
import type { ProposalEnquirySearchCriteria } from "domain/entity/ProposalEnquiry/ProposalEnquirySearchCriteria";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import { AclType } from "presentation/constant/ANAInfo/NbisivPermission";
import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { useProposalEnquiryVM } from "presentation/hook/ProposalEnquiry/useProposalEnquiryVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { isPermissionExist } from "presentation/utils/permissionUtils";
import { OverflowMenuButton } from "presentation/view/components/OverflowMenuButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HPHButton, IconButton, OverflowingSubMenu } from "veronica-ui-component/dist/component/core";

export const ProposalEnquiryHeader: React.FC = () => {
    // const [title, setTitle] = useState('PROPOSAL SEARCH');
    // const [isList, setIsList] = useState(true);
    // const [isEditCriteria, setIsEditCriteria] = useState(false);
    //const [isDirectory, setIsDirectory] = useState(false);

    // const [isEdit,] = useState(false);
    // const [isShowLessDetail, setIsShowLessDetail] = useState(false);
    // const [isFullScreen, setIsFullScreen] = useState(false);
    // const [proposalEnquiryModel, setProposalEnquiryModel] = useState<ProposalEnquiryModel>(EMPTY_PROPOSAL_ENQUIRY_MODEL);
    const [isShowLegend] = useState<boolean>(false);
    // const [enableActivate, setEnableActivate] = useState(false);

    const proposalEnquiryVM = useProposalEnquiryVM();

    const [proposalEnquiryState] = useProposalEnquiryTracked();

    const [anainfoState] = useANAInfoTracked();
    const isActivateRead = isPermissionExist(Permission.PROPOSAL_ENQUIRY_ACTIVATE, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isActivateUpdate = isPermissionExist(Permission.PROPOSAL_ENQUIRY_ACTIVATE, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isDeactivateRead = isPermissionExist(Permission.PROPOSAL_ENQUIRY_DEACTIVATE, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isDeactivateUpdate = isPermissionExist(Permission.PROPOSAL_ENQUIRY_DEACTIVATE, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);

    // useEffect(() => {
    //     setProposalEnquiryModel(proposalEnquiryState)
    // }, [proposalEnquiryState])

    const handleFullScreen = useCallback(() => {
        customDispatchEvent(E_Type_Of_Event.MODAL_EVENT, E_Custom_Dispatch_Event.MODAL_FULL_SCREEN_MODE, { isFullScreen: !proposalEnquiryState.isFullScreen, className: 'proposal-search-modal' });
        // setIsFullScreen(!isFullScreen);
        // setProposalEnquiryModel({
        //     ...proposalEnquiryModel,
        //     isShowLegendButton: !proposalEnquiryModel.isShowLegendButton
        // });
        proposalEnquiryVM.handleFullScreen();
    }, [proposalEnquiryState.isFullScreen, proposalEnquiryVM]);

    const handleClose = useCallback(() => {
        localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY);
        customDispatchEvent(E_Type_Of_Event.MODAL_EVENT, E_Custom_Dispatch_Event.MODAL_CLOSE, { showProposalSearch: false });
    }, []);

    // Back button done
    const handleBackClick = useCallback(() => {
        // customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_MODAL_BACK_TO_LIST, {});
        proposalEnquiryVM.closeProposalDetail();
    }, [proposalEnquiryVM]);

    const handleDetailClose = useCallback(() => {
        // if (!isEdit) customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_MODAL_BACK_TO_LIST, {});
        proposalEnquiryVM.closeProposalDetail();
    }, [proposalEnquiryVM]);

    const handleActivate = useCallback(() => {
        // let objDispatch: { [x: string]: boolean } = { isLoading: true };
        // customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_REFRESH_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_UPDATE, objDispatch);
        proposalEnquiryVM.onActivate(proposalEnquiryState.selectedRows).then((data) => {
            if (typeof data !== 'string' && proposalEnquiryState.searchCriteria) {
                Promise.allSettled([
                    proposalEnquiryVM.onSearch(),
                    proposalEnquiryVM.fetchTableData(proposalEnquiryState.searchCriteria, EMPTY_SEARCH_MODE_ENTITY)
                ])
            }
        });
    }, [proposalEnquiryState.selectedRows, proposalEnquiryVM, proposalEnquiryState.searchCriteria]);

    const handleDeactivate = useCallback(() => {
        // customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_ENABLE_DEACTIVATE, {});
        // let objDispatch: { [x: string]: boolean } = { isLoading: true };
        // customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_REFRESH_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_UPDATE, objDispatch);
        proposalEnquiryVM.onDeactivate(proposalEnquiryState.selectedRows).then((data) => {
            if (typeof data !== 'string' && proposalEnquiryState.searchCriteria) {
                Promise.allSettled([
                    proposalEnquiryVM.onSearch(),
                    proposalEnquiryVM.fetchTableData(proposalEnquiryState.searchCriteria, EMPTY_SEARCH_MODE_ENTITY)
                ])
            }
        });
    }, [proposalEnquiryState.selectedRows, proposalEnquiryVM, proposalEnquiryState.searchCriteria]);

    // const onProposalEnquiryChange = (e: any) => {
    //     switch (e.detail.action) {
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_EDIT_CRITERIA_BACK:
    //             setIsEditCriteria(e.detail.data.isEdit);
    //             setEnableActivate(e.detail.data.enableActivate);
    //             setProposalEnquiryModel(prev => ({ ...prev, selectedRows: e.detail.data.selectedRows }));
    //             break;
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_HIDE_RIGHT_PANEL:
    //             setIsEditCriteria(true);
    //             break;
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_PREFERENCE_GET:
    //             setProposalEnquiryModel(e.detail.data.proposalEnqModel);
    //             break;
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_PREFERENCE_CURRENT_UPDATE:
    //             setProposalEnquiryModel(prev => ({ ...prev, currentSearchPreference: e.detail.data.currentSearchPreference }));
    //             break;
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_DETAIL_SHOW:
    //             setTitle('PROPOSAL DETAILS');
    //             setIsList(false);
    //             e.detail.data.isFullScreen && handleFullScreen();
    //             break;
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_DETAIL_HIDE:
    //             setTitle('Proposal List');
    //             setIsList(true);
    //             break;
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_ENABLE_ACTIVATE_BUTTON:
    //             setEnableActivate(e.detail.data.enableActivate)
    //             setProposalEnquiryModel(prev => ({ ...prev, selectedRows: e.detail.data.selectedRows, searchCriteria: e.detail.data.searchCriteria }));
    //             break;
    //     }
    // }

    // const overFlowMenu = useMemo(() => <OverflowMenuButton icon='Icon-bookmark' navigationList={preferenceMenu} />, [preferenceMenu]);    

    // useEffect(() => {
    //     customEventListener(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, onProposalEnquiryChange);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // Search Criteria Edit
    const handleCriteriaEditClick = useCallback(() => {
        // customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_EDIT_CRITERIA, { isShowRightCriteriaPanel: !isEditCriteria });
        // setIsEditCriteria(!isEditCriteria);
        //setIsDirectory(false);
        proposalEnquiryVM.handleCriteriaEditClick();
    }, [proposalEnquiryVM]);

    const handleSaveSearchPreference = useCallback(() => {
        const currentSearchPreference: SearchPreferenceEntity<ProposalEnquirySearchCriteria, EnabledProposalEnquiryCriteria> = {
            ...(proposalEnquiryState.currentSearchPreference ?? EMPTY_SEARCH_PREFERENCE_ENTITY),
            searchCriteria: proposalEnquiryState.searchCriteria,
            criteriaDirectory: proposalEnquiryState.enabledSearchCriteria
        }
        proposalEnquiryVM.saveSearchPreference(currentSearchPreference);
    }, [proposalEnquiryState.currentSearchPreference, proposalEnquiryState.enabledSearchCriteria, proposalEnquiryState.searchCriteria, proposalEnquiryVM]);

    const preferenceMenu: OverflowingSubMenu[] = useMemo(() => {
        const menuItemArray: OverflowingSubMenu[] = []
        if (proposalEnquiryState.searchPreferences.length) {
            menuItemArray.push({
                title: 'Open preference',
                submenu: proposalEnquiryState.searchPreferences?.map(searchPreference => ({
                    title: searchPreference.preferenceName,
                    icon: proposalEnquiryState.currentSearchPreference?.key === searchPreference.key ? 'hph-icon Icon-masterbookmark-fill' : '',
                    command: () => proposalEnquiryVM.loadSearchPreference(searchPreference),
                }))
            })
        }

        if (proposalEnquiryState.currentSearchPreference?.userEmail === proposalEnquiryState.userEmail) {
            menuItemArray.push({
                title: 'Save preference',
                command: handleSaveSearchPreference,
            })
        }

        menuItemArray.push({
            title: 'Save as new preference...',
            command: () => proposalEnquiryVM.openSaveNewConfirmModal(),
        });

        const ownDashboards = proposalEnquiryState.searchPreferences?.filter((searchPreference) => searchPreference.userEmail === proposalEnquiryState.userEmail)
        if (ownDashboards.length) {
            menuItemArray.push({
                title: 'Delete preference...',
                command: () => proposalEnquiryVM.openDeleteConfirmModal(),
            })
        }

        return menuItemArray;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proposalEnquiryState.searchPreferences, proposalEnquiryState.currentSearchPreference, proposalEnquiryState.searchCriteria,
    proposalEnquiryState.enabledSearchCriteria, proposalEnquiryState.userEmail, handleSaveSearchPreference]);

    const overFlowMenu = useMemo(() => <OverflowMenuButton icon='Icon-bookmark' navigationList={preferenceMenu} />, [preferenceMenu]);

    function addLegendToParent(pDivId: string, cDivId: string, headerBox: string) {
        const timer = setInterval(() => {
            const pDiv = document.querySelector(pDivId);
            const cDiv = document.querySelector(cDivId);
            const box = document.querySelector(headerBox);
            const cls: string = cDiv?.getAttribute('class')?.toString() || '';

            if (cls.indexOf('added') >= 0) clearInterval(timer);

            if (pDiv && cDiv && box && cls.indexOf('added') < 0) {
                const pos = pDiv?.getBoundingClientRect();
                const boxPos = box?.getBoundingClientRect();
                cDiv.setAttribute('class', `${cls} added`);
                cDiv.setAttribute('style', `left:${pos && (pos.left - (boxPos?.width ?? 0) * 0.38)}px;top:${boxPos && (boxPos.top + 10)}px;`);
            }
        }, 200);
    }

    useEffect(() => {
        addLegendToParent('#Icon-help-charge-detail-table', '#charge-detail-legend', '#charge-data-search-header-box');
    }, [isShowLegend, proposalEnquiryState.isFullScreen, proposalEnquiryState.isShowLegendButton]);

    const getListHeader: JSX.Element = useMemo(() => {
        return <>
            <div id="charge-data-search-header-box" className="md-header-box">
                <span className="md-header-title" style={{ flexGrow: 1 }}>{proposalEnquiryState.title}</span>
                <div className="md-header-option" style={{ flexGrow: 0, justifyContent: "space-between", flexShrink: 0 }}>
                    {proposalEnquiryState.enableActivate && isActivateRead && isActivateUpdate && <HPHButton label={'Activate'} size={'Small'} theme={'Primary'} onClick={handleActivate} />}
                    {proposalEnquiryState.enableActivate && isDeactivateRead && isDeactivateUpdate && <HPHButton label={'Deactivate'} size={'Small'} theme={'Primary'} onClick={handleDeactivate} />}
                    {proposalEnquiryState.isEditCriteria && <IconButton fileName='Icon-pen' tooltipDisable={false} size="medium" onClick={handleCriteriaEditClick} toolTipText='Edit Criteria' />}
                    {<div style={{ width: "32px", height: "32px" }}>{overFlowMenu}</div>}
                </div>
            </div>
            <div className={`md-header-action`} style={{ width: '75px' }}>
                <IconButton fileName={proposalEnquiryState.isFullScreen ? 'Icon-minimise' : 'Icon-enlarge'} tooltipDisable={false} toolTipText={!proposalEnquiryState.isFullScreen ? 'Maximise' : 'Restore'} size="medium" onClick={handleFullScreen} />
                <IconButton fileName="Icon-cross" toolTipText="Close" tooltipDisable={false} size="medium" onClick={handleClose} />
            </div>
        </>
    }, [proposalEnquiryState.title, proposalEnquiryState.enableActivate, isActivateRead, isActivateUpdate, handleActivate, isDeactivateRead, isDeactivateUpdate,
        handleDeactivate, proposalEnquiryState.isEditCriteria, handleCriteriaEditClick, overFlowMenu, proposalEnquiryState.isFullScreen, handleFullScreen, handleClose]);

    const getDetailHeader: JSX.Element = useMemo(() => {
        return <>
            <div className="md-header-box">
                <span className="md-header-title" style={{ flexGrow: 1 }}>
                    {!proposalEnquiryState.isEdit && <IconButton fileName='Icon-arrow-left' toolTipText='Back' toolTipPlacement="right" onClick={handleBackClick} />}
                    <span>{proposalEnquiryState.title}</span>
                </span>
            </div>
            <div className={`md-header-action`} style={{ width: (proposalEnquiryState.isEdit ? '106px' : '75px') }}>
                <IconButton fileName={proposalEnquiryState.isFullScreen ? 'Icon-minimise' : 'Icon-enlarge'} tooltipDisable={false} toolTipText={!proposalEnquiryState.isFullScreen ? 'Maximise' : 'Restore'} size="medium" onClick={handleFullScreen} />
                <IconButton fileName='Icon-cross' size="medium" onClick={handleDetailClose} toolTipText='Close' />
            </div>
        </>
    }, [proposalEnquiryState.title, proposalEnquiryState.isFullScreen, proposalEnquiryState.isEdit, handleBackClick, handleFullScreen, handleDetailClose]);

    if (proposalEnquiryState.isList) return getListHeader;

    return getDetailHeader;
}