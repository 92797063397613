import { ProposalEnquiryConstant } from "presentation/constant/ProposalEnquiry/ProposalEnquiryConstant";
import { useProposalEnquiryDetailTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useMemo, useState } from "react";
import { CommonField, FieldType, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const ProposalEnquiryHeaderFieldFormPanel = () => {
    const [proposalEnqDtlState] = useProposalEnquiryDetailTracked();

    const { isSaveClicked, allFormState } = proposalEnqDtlState.detailState;
    const { currentProposalEnquiry } = proposalEnqDtlState;


    const [isLoading,] = useState(false);

    const PROPOSA_ENQ_HDR_CONST = ProposalEnquiryConstant.Header;



    // const handleEdit = useCallback(() => {
    //     currentProposalEnquiry && proposalEnqDtlVM.onEdit(currentProposalEnquiry);
    // },[currentProposalEnquiry, proposalEnqDtlVM])


    const memoCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentProposalEnquiry.companyCode ?? ''}
                fieldValue={currentProposalEnquiry.companyCode ?? ''}
                fieldLabel={PROPOSA_ENQ_HDR_CONST.COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'companyCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [PROPOSA_ENQ_HDR_CONST.COMPANY_CODE, allFormState, currentProposalEnquiry.companyCode, isSaveClicked])

    const memoProposalNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentProposalEnquiry.proposalNo ?? ''}
                fieldValue={currentProposalEnquiry.proposalNo ?? ''}
                fieldLabel={PROPOSA_ENQ_HDR_CONST.PROPOSAL_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'proposalNo'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [PROPOSA_ENQ_HDR_CONST.PROPOSAL_NO, allFormState, currentProposalEnquiry.proposalNo, isSaveClicked])

    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentProposalEnquiry.operatingTml ?? ''}
                fieldValue={currentProposalEnquiry.operatingTml ?? ''}
                fieldLabel={PROPOSA_ENQ_HDR_CONST.OPERATING_TML}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'operatingTml'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [PROPOSA_ENQ_HDR_CONST.OPERATING_TML, allFormState, currentProposalEnquiry.operatingTml, isSaveClicked])


    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <DatePickerComponent
                disabled={true}
                label={PROPOSA_ENQ_HDR_CONST.EFFECTIVE_DATE}
                width="150px"
                date={currentProposalEnquiry?.effectiveDate}
                fieldName="effectiveDate"
                onDateChange={() => { }} />
        </div>
        , [PROPOSA_ENQ_HDR_CONST.EFFECTIVE_DATE, currentProposalEnquiry?.effectiveDate])

    const memoSeq = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={PROPOSA_ENQ_HDR_CONST.SEQ}
                width='150px'
                fieldName={"seq"}
                value={currentProposalEnquiry.seq || ''}
                disabled={true}
                decimalPlaces={0}
            />
            {/* <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentProposalEnquiry.seq ?? ''}
                fieldValue={currentProposalEnquiry.seq}
                fieldLabel={PROPOSA_ENQ_HDR_CONST.SEQ}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'seq'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} /> */}
        </div>
        , [PROPOSA_ENQ_HDR_CONST.SEQ, currentProposalEnquiry.seq])

    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentProposalEnquiry.tariffType ?? ''}
                fieldValue={currentProposalEnquiry.tariffType ?? ''}
                fieldLabel={PROPOSA_ENQ_HDR_CONST.TAR_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffType'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [PROPOSA_ENQ_HDR_CONST.TAR_TYPE, allFormState, currentProposalEnquiry.tariffType, isSaveClicked])

    const memoTariffCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentProposalEnquiry.tariffCode ?? ''}
                fieldValue={currentProposalEnquiry.tariffCode ?? ''}
                fieldLabel={PROPOSA_ENQ_HDR_CONST.TAR_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [PROPOSA_ENQ_HDR_CONST.TAR_CODE, allFormState, currentProposalEnquiry.tariffCode, isSaveClicked])

    const memoCurrencyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentProposalEnquiry.currencyCode ?? ''}
                fieldValue={currentProposalEnquiry.currencyCode ?? ''}
                fieldLabel={PROPOSA_ENQ_HDR_CONST.CURRENCY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'currencyCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [PROPOSA_ENQ_HDR_CONST.CURRENCY, allFormState, currentProposalEnquiry.currencyCode, isSaveClicked])

    const memoForwarderCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentProposalEnquiry.forwarderCode ?? ''}
                fieldValue={currentProposalEnquiry.forwarderCode ?? ''}
                fieldLabel={PROPOSA_ENQ_HDR_CONST.FORWARDER}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'forwarderCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [PROPOSA_ENQ_HDR_CONST.FORWARDER, allFormState, currentProposalEnquiry.forwarderCode, isSaveClicked])

    const memoProposalType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentProposalEnquiry.proposalType ?? ''}
                fieldValue={currentProposalEnquiry.proposalType ?? ''}
                fieldLabel={PROPOSA_ENQ_HDR_CONST.PROPOSAL_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'proposalType'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [PROPOSA_ENQ_HDR_CONST.PROPOSAL_TYPE, allFormState, currentProposalEnquiry.proposalType, isSaveClicked])

    const memoProposalStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentProposalEnquiry.proposalStatus ?? ''}
                fieldValue={currentProposalEnquiry.proposalStatus ?? ''}
                fieldLabel={PROPOSA_ENQ_HDR_CONST.STATUS}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'proposalStatus'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [PROPOSA_ENQ_HDR_CONST.STATUS, allFormState, currentProposalEnquiry.proposalStatus, isSaveClicked])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentProposalEnquiry.activeInd ?? ''}
                fieldValue={currentProposalEnquiry.activeInd ?? ''}
                fieldLabel={PROPOSA_ENQ_HDR_CONST.ACTIVE_IND}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'activeInd'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [PROPOSA_ENQ_HDR_CONST.ACTIVE_IND, allFormState, currentProposalEnquiry.activeInd, isSaveClicked])

    const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentProposalEnquiry.remarks ?? ''}
                fieldValue={currentProposalEnquiry.remarks ?? ''}
                fieldLabel={PROPOSA_ENQ_HDR_CONST.REMARKS}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'remarks'}
                maxLength={4000}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [PROPOSA_ENQ_HDR_CONST.REMARKS, allFormState, currentProposalEnquiry.remarks, isSaveClicked])

    return <>
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{currentProposalEnquiry.proposalNo ?? 'Header'}</SidebarTitle>
                        <SidebarActionCross>
                        </SidebarActionCross>

                    </Sidebarheader>
                </div>
            </div>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            <>
                <div className={'add-edit-form'} style={{ height: '78vh', maxHeight: '78vh', overflow: 'auto' }}>
                    <div className="flex-row-auto">
                        <div className={'flex-row-item'}>
                            <CriteriaItemContainer>
                                {memoCompanyCode}{memoProposalNo}{memoOperatingTml}
                            </CriteriaItemContainer>
                            <CriteriaItemContainer>
                                {memoEffectiveDate}{memoSeq}
                            </CriteriaItemContainer>
                            <CriteriaItemContainer>
                                {memoTariffType}{memoTariffCode}
                            </CriteriaItemContainer>
                            <CriteriaItemContainer>
                                {memoCurrencyCode} {memoForwarderCode}
                            </CriteriaItemContainer>
                            <CriteriaItemContainer>
                                {memoProposalType} {memoProposalStatus} {memoActiveInd}
                            </CriteriaItemContainer>
                            <CriteriaItemContainer>
                                {memoRemarks}
                            </CriteriaItemContainer>

                        </div>
                    </div>
                </div>
            </>
        </div>
    </>

}
